<template lang="pug">
  v-app-bar(app color='#009688' dark)
      div
        router-link(to="/" )
          v-img(:src="require('@/assets/my-playground-logo.png')" contain max-width='120' alt='My Playground Logo')
      v-spacer
      div.d-flex(v-if='$vuetify.breakpoint.width > 500')
        router-link(to='/')
          v-btn(large depressed text)
            span.mr-2 Home
        //- router-link(to='/about')
          v-btn(large depressed text)
            span.mr-2 About
        router-link(to='/editor')
          v-btn(large depressed text)
            span.mr-2 Heditor
      v-menu(offset-y  left bottom v-if='$vuetify.breakpoint.width<=500' transition="slide-y-transition")
        template(v-slot:activator='{ on, attrs }')
          v-btn(icon v-bind='attrs' v-on='on')
            v-icon mdi-xml
        v-list
          router-link(to='/')
            v-list-item
              v-list-item-title Home
          router-link(to='/editor')
            v-list-item
              v-list-item-title Heditor

</template>
<script>
export default {
  name: 'TopNavBar'
}
</script>
<style scoped>
a{
  text-decoration: none;
}
</style>