<template lang="pug">
.MyBuilder.pa-5
  div#myeditorToolbar
    v-menu( v-model="typoMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
      template( v-slot:activator="{ on, attrs }" )
        v-btn.mainbar-btn.mr-5(v-bind="attrs" v-on="on")
          img( :src="require('@/assets/icon/type-setting.svg')" )
      v-card(  )
        v-card-text.py-2.px-0
          v-row.type-setting-1
            v-btn.mainbar-btn( @click="bold()" ) B
            v-btn.mainbar-btn( @click="italic()" ) 
              em i
            v-btn.mainbar-btn( @click="underline()" ) 
              u U
            v-btn.mainbar-btn( @click="strikeThrough()" )
              strike S
          v-row.type-setting-2
            v-btn.mainbar-btn.textsize-headline( @click="insertTextElement('textsize-headline', null)") Headline
            v-btn.mainbar-btn.textsize-subheadline( @click="insertTextElement('textsize-subheadline', null)") Subheadline
            v-btn.mainbar-btn.textsize-title( @click="insertTextElement('textsize-title', null)") Title
            v-btn.mainbar-btn.textsize-paragraph( @click="insertTextElement('textsize-paragraph', null)") Paragraph
          v-row.type-setting-3
            v-btn.mainbar-btn( @click="changeFontFamily('textfont-inter')" style="font-family: 'Inter'")
              | Sans Serif
            v-btn.mainbar-btn( @click="changeFontFamily('textfont-libre')" style="font-family: 'Libre Baskerville'") 
              | Serif
            v-btn.mainbar-btn( @click="changeFontFamily('textfont-courier')" style="font-family: 'Courier Prime'") 
              | Monospace
            v-btn.mainbar-btn( @click="changeFontFamily('textfont-slab')" style="font-family: 'Roboto Slab'")
              | Slab Serif
            v-btn.mainbar-btn( @click="changeFontFamily('textfont-courgette')" style="font-family: 'Courgette'")
              | Script
            v-btn.mainbar-btn( @click="changeFontFamily('textfont-comic')" style="font-family: 'Comic Neue'")
              | Handwriting
    v-menu( v-model="paraMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
      template( v-slot:activator="{ on, attrs }" )
        v-btn.mainbar-btn.mr-5(v-bind="attrs" v-on="on")
          img( :src="require('@/assets/icon/paragraph.svg')" )
      v-card(  )
        v-card-text
          v-row.paragraph-setting-1
            div.mr-5
              v-btn.mainbar-btn( @click="alignText('left')" )
                img( :src="require('@/assets/icon/text-align-left.svg')" )
              v-btn.mainbar-btn( @click="alignText('center')" )
                img( :src="require('@/assets/icon/text-align-center.svg')" )
              v-btn.mainbar-btn( @click="alignText('right')" )
                img( :src="require('@/assets/icon/text-align-right.svg')" )
              v-btn.mainbar-btn( @click="alignText('justify')" )
                img( :src="require('@/assets/icon/text-align-justify.svg')" )
            div.ml-5
              v-btn.mainbar-btn( @click="currentBullet = '•',insertList('ul',null)" )
                img( :src="require('@/assets/icon/dotted-bullet.svg')" )
              v-btn.mainbar-btn( @click="currentBullet = '-',insertList('ul',null)" )
                img( :src="require('@/assets/icon/dash-bullet.svg')" )
              v-btn.mainbar-btn( @click="currentBullet = '',insertList('ol',null)" )
                img( :src="require('@/assets/icon/numbered-bullet.svg')" )
              v-menu( v-model="emojiBulletMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
                template( v-slot:activator="{ on, attrs }" )
                  v-btn.mainbar-btn.mr-5(v-bind="attrs" v-on="on")
                    img( :src="require('@/assets/icon/emoji-bullet.svg')" )
                v-card(  )
                  v-card-text
                    ul
                      li.d-flex.py-1(style="list-type:none" v-for="(list, index) in emojiList" :key="index")
                        v-btn.mainbar-btn( @click="currentBullet = list.emoji,insertList('ul',null)")
                          p.mb-0 {{list.emoji}}
            div.ml-5
              v-btn.mainbar-btn( @click="indentOutdent('inc')" )
                img( :src="require('@/assets/icon/inc-indent.svg')" )
              v-btn.mainbar-btn( @click="indentOutdent('dec')" )
                img( :src="require('@/assets/icon/dec-indent.svg')" )
    v-menu( v-model="fontStyleMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
      template( v-slot:activator="{ on: menu, attrs }" )
        v-tooltip( bottom)
          template( v-slot:activator="{ on: tooltip }")
            v-btn.mainbar-btn.mr-5(v-bind="attrs" v-on="{...tooltip,...menu}")
              img( :src="require('@/assets/icon/font-style.svg')" )
          span Text Style
      v-card(  )
        v-card-text
          v-row.paragraph-setting-1
            v-menu( v-model="fontColorMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
              template( v-slot:activator="{ on: menu, attrs }" )
                v-tooltip( bottom)
                  template( v-slot:activator="{ on: tooltip }")
                    v-btn.mainbar-btn(v-bind="attrs" v-on="{...tooltip,...menu}")
                      img( :src="require('@/assets/icon/font-color.svg')" )
                  span Text Color
              v-card(  )
                v-card-text
                  ul
                    li.d-flex.py-1(style="list-type:none" v-for="(col, index) in colorList" :key="index")
                      v-btn.mainbar-btn( @click="changeTextColor('textcolor-'+col.name)") 
                        div.fontcolor-prev(:style="'background-color:'+col.code")
                        p.mb-0 {{col.name}}
            v-menu( v-model="fontBgMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
              template( v-slot:activator="{ on: menu, attrs }" )
                v-tooltip( bottom)
                  template( v-slot:activator="{ on: tooltip }")
                    v-btn.mainbar-btn(v-bind="attrs" v-on="{...tooltip,...menu}")
                      img( :src="require('@/assets/icon/font-bg.svg')" )
                  span Text Background
              v-card(  )
                v-card-text
                  ul
                    li.d-flex.py-1(style="list-type:none" v-for="(col, index) in bgColorList" :key="index")
                      v-btn.mainbar-btn( @click="changeBgColor('bgcolor-'+col.name)") 
                        div.fontbg-prev(:style="'background-color:'+col.code") A
                        p.mb-0 {{col.name}}
    v-menu( v-model="mediaMenu" :close-on-content-click="true" :nudge-width="200" offset-y )
      template( v-slot:activator="{ on: menu, attrs }" )
        v-tooltip( bottom)
          template( v-slot:activator="{ on: tooltip }")
            v-btn.mainbar-btn.mr-5(v-bind="attrs" v-on="{...tooltip,...menu}")
              img( :src="require('@/assets/icon/insert-media.svg')" )
          span Insert Media
      v-card(  )
        v-card-text
          v-row.paragraph-setting-1
            div
              v-btn.mainbar-btn( @click="openChooseImage = true" )
                img( :src="require('@/assets/icon/insert-image.svg')" )
              v-btn.mainbar-btn( @click="openChooseVideo = true" )
                img( :src="require('@/assets/icon/insert-image.svg')" )
              //- v-btn.mainbar-btn( @click="" )
                img( :src="require('@/assets/icon/insert-link.svg')" )
    v-tooltip( bottom)
      template( v-slot:activator="{ on: tooltip }") 
        v-btn.mainbar-btn.mr-5(v-on="{...tooltip}" @click="openChooseButton = true")
          img( :src="require('@/assets/icon/insert-button.svg')" )
      span Insert Button
      
  div#myeditor(@click="insertToEditor()")
    //- add font at index.html 
    //- <link href="https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&family=Courgette&family=Courier+Prime:wght@400;700&family=Inter:wght@400;500;600;700&family=Libre+Baskerville:wght@400;700&family=Roboto+Slab:wght@400;500;600;700&display=swap" rel="stylesheet">
    div#heditor-wrapper(style="max-width:600px; margin: auto; padding: 20px 10px" )
  
  //- input(type="file" @change="uploadImage($event)" )
  v-dialog( v-model="openChooseImage" max-width="350" )
    v-card(  )
      v-card-title Insert Image
      v-card-text
        input.pa-0.ma-0(type="file" id="chooseImage" style="width:100%" accept="image/*")
        p.my-4 or
        v-text-field.pa-0.ma-0(type="text" placeholder="Insert image link" id="imageUrl")
        h4.mt-5 Uploaded Image (click to insert)
        v-row.mt-0(v-if="uploadedImage.length" )
          v-col(cols="6" v-for="(item,index) in uploadedImage" :key="index" @click="insertUploadedImage(item.link)" style="cursor:pointer; word-break: break-all")
            img(:src="item.link" :alt="item.filename" width="100%" )
        p.mb-0(v-else) No image uploaded
      v-card-actions
        v-btn.ml-auto.text-capitalize( @click="uploadImage()" depressed color="primary" ) Insert
        v-btn.text-capitalize( @click="openChooseImage = false" depressed outlined color="primary" ) Close
  v-dialog( v-model="openChooseButton" max-width="350" )
    v-card(  )
      v-card-title Choose Button Style
      v-card-text
        v-row.paragraph-setting-1.py-3.px-4(style="max-width: unset; width:100%; height: 52px")
          v-col.pa-0(cols="12")
            p.textsize-paragraph.mb-0 Select your button shape
        v-row.paragraph-setting-1.pt-0.pb-3.px-4.mt-0(style="max-width: unset; width:100%; height: 52px")
          v-col.pa-0(cols="12" style="display: flex; align-items: left; justify-content: space-between;")
            div(style="width: 45px; height: 17px; border: 2px solid #000000; border-radius:0; cursor:pointer;" @click="currentButtonRadius = '0px'")
            div(style="width: 45px; height: 17px; border: 2px solid #000000; border-radius:5px; cursor:pointer;x" @click="currentButtonRadius = '10px'")
            div(style="width: 45px; height: 17px; border: 2px solid #000000; border-radius:20px; cursor:pointer;" @click="currentButtonRadius = '60px'")
        v-row.paragraph-setting-1.py-3.px-4(style="max-width: unset; width:100%; height: 52px")
          v-col.pa-0(cols="12")
            p.textsize-paragraph.mb-0 Select your button color
        v-row.paragraph-setting-1.pt-0.pb-3.px-4.mt-0(style="max-width: unset; width:100%; height: 52px")
          v-col.pa-0(cols="12" style="display: flex; align-items: left; justify-content: left;")
            div.mr-3(style="width:24px; height:24px; border-radius: 6px; cursor:pointer" :style="{ backgroundColor: currentButtonColor }" @click="openChooseColor=true")
            div.textsize-paragraph(style="width:fit-content" ) {{currentButtonColor}}
        v-row.paragraph-setting-1.py-3.px-4(style="max-width: unset; width:100%; height: fit-content")
          v-col.pa-0(cols="12")
            button(style="padding: 17px 10px; width: 179px; height: 56px;" :style="{ backgroundColor: currentButtonColor, borderRadius: currentButtonRadius }" @click="insertButton(), openChooseButton=false") 
              span(style="font-weight: 600; font-size: 17px; line-height: 22px; color: #ffffff") Insert Button
      v-card-actions
        v-btn.text-capitalize.ml-auto( @click="openChooseButton = false" depressed outlined color="primary" ) Close
  v-dialog( v-model="openChooseColor" max-width="350" persistent)
    v-card()
      v-card-title
        p.mb-0 Choose Button Color
      v-card-text
        v-color-picker(dot-size="5" hide-canvas hide-mode-switch hide-sliders mode="hexa" show-swatches swatches-max-height="230" v-model="currentButtonColor" )
      v-card-actions
        v-btn.text-capitalize.ml-auto( @click="openChooseColor = false" depressed outlined color="primary" ) Confirm
  v-dialog( v-model="openChooseVideo" max-width="350" )
    v-card(  )
      v-card-title Insert Video
      v-card-text
        input.pa-0.ma-0(type="file" id="chooseVideo" style="width:100%" accept="video/*")
        p.my-4 or
        v-text-field.pa-0.ma-0(type="text" placeholder="Insert video link" id="videoUrl")
        h4.mt-5 Uploaded Video (click to insert)
        v-row.mt-0(v-if="uploadedVideo.length" )
          v-col(cols="6" v-for="(item,index) in uploadedVideo" :key="index" @click="insertUploadedVideo(item.link)" style="cursor:pointer; word-break: break-all")
            video(width="100%")
              source(src="https://cdn.yezza.io/documents/e79d726d438441a1bcd16f0b2970651e.mp4")
        p.mb-0(v-else) No video uploaded
      v-card-actions
        v-btn.ml-auto.text-capitalize( @click="uploadVideo()" depressed color="primary" ) Insert
        v-btn.text-capitalize( @click="openChooseVideo = false" depressed outlined color="primary" ) Close
</template>

<script>
// import { DOMParser } from 'xmldom'
import axios from "axios";

export default {
  name: 'MyBuilder',
  data: ()=>({
    typoMenu: false,
    paraMenu: false,
    fontColorMenu: false,
    fontBgMenu: false,
    fontStyleMenu: false,
    emojiBulletMenu: false,
    mediaMenu: false,
    openChooseImage: false,
    openChooseVideo: false,
    openChooseColor: false,
    openChooseButton: false,
    buttonMenu: false,
    currentBullet:"",
    uploadedImage:[],
    uploadedVideo:[],
    currentButtonColor: "#3366ffff",
    currentButtonRadius: "0",
    bgColorList: [
      {
        name: 'default',
        code: 'none'
      },
      {
        name: 'gray',
        code: 'rgba(51, 51, 51, 0.08)'
      },
      {
        name: 'brown',
        code: 'rgba(166, 96, 73, 0.16)'
      },
      {
        name: 'orange',
        code: 'rgba(224, 110, 56, 0.19)'
      },
      {
        name: 'yellow',
        code: 'rgba(219, 170, 0, 0.22)'
      },
      {
        name: 'green',
        code: 'rgba(0, 131, 100, 0.1)'
      },
      {
        name: 'blue',
        code: 'rgba(44, 129, 228, 0.14)'
      },
      {
        name: 'purple',
        code: 'rgba(111, 98, 255, 0.12)'
      },
      {
        name: 'pink',
        code: 'rgba(220, 59, 117, 0.13)'
      },
      {
        name: 'red',
        code: 'rgba(205, 12, 0, 0.15)'
      }
    ],
    colorList: [
      {
        name: 'default',
        code: '#000000'
      },
      {
        name: 'gray',
        code: '#848484'
      },
      {
        name: 'brown',
        code: '#A66049'
      },
      {
        name: 'orange',
        code: '#E06E38'
      },
      {
        name: 'yellow',
        code: '#DBAA00'
      },
      {
        name: 'green',
        code: '#008364'
      },
      {
        name: 'blue',
        code: '#2C81E4'
      },
      {
        name: 'purple',
        code: '#6F62FF'
      },
      {
        name: 'pink',
        code: '#DC3B75'
      },
      {
        name: 'red',
        code: '#CD3300'
      }
    ],
    emojiList: [
      {
        emoji: '👉'
      },
      {
        emoji: '👍'
      },
      {
        emoji: '✍'
      },
      {
        emoji: '🌺'
      },
      {
        emoji: '⚡'
      },
      {
        emoji: '✨'
      },
      {
        emoji: '🎉'
      },
      {
        emoji: '🎁'
      },
      {
        emoji: '✅'
      },{
        emoji: '❌'
      }
    ]
  }),
  mounted() {
    // this.uploadedImage = JSON.parse(window.localStorage.getItem('sales-page-images'))
    const savedUploaded = window.localStorage.getItem('sales-page-images')
    if (savedUploaded !== null && savedUploaded !== ''){
      this.uploadedImage = JSON.parse(savedUploaded)
    }
    const savedUploadedVideo = window.localStorage.getItem('sales-page-videos')
    if (savedUploadedVideo !== null && savedUploadedVideo !== ''){
      this.uploadedVideo = JSON.parse(savedUploadedVideo)
    }
    this.insertTextElement('textsize-headline',null)
    const editor = document.querySelector('#myeditor');
    editor.addEventListener( 'keydown', (e) => {
      // console.log(e.target);
      if (e.target.classList.contains("yz-text-element")) {
        const keyCode = e.keyCode
        console.log('keycode:', keyCode,' | keyname:', e.key);
        // alert(keyCode)
        if (keyCode === 13 && !e.shiftKey && this.getFocusedElement().tagName !== 'LI') { // enter keyCode
          // Don't generate a new line
          e.preventDefault()
          if (e.target.innerHTML.length === 0) {
            e.target.innerHTML = " "
          }
          this.insertTextElement('textsize-paragraph', keyCode)
        }
        if (keyCode === 13 && this.getFocusedElement().tagName === 'LI' && this.getFocusedElement().innerHTML === '<br>') { // enter keyCode
          // console.log(this.getFocusedElement());
          e.preventDefault()
          this.getFocusedElement().remove()
          this.insertTextElement('textsize-paragraph', keyCode)
        }

        if (keyCode === 8) { // delete/backspace keyCode
        // console.log(e.target.lastChild.tagName);
          if (e.target.innerHTML.length === 0) {
            // console.log(e.target.parentNode.tagName);
            if (e.target.classList.contains("yz-text-element")) {
              if (e.target.previousElementSibling) {
                e.preventDefault()
                if (e.target.previousElementSibling.classList.contains("yz-text-element")) {
                  const el = e.target.previousElementSibling
                  this.placeCaretAtEnd(el)
                }
                else if (e.target.previousElementSibling.classList.contains("yz-list-element")) {
                  const el = e.target.previousElementSibling.lastChild.lastChild
                  this.placeCaretAtEnd(el)
                }
              }
              e.target.remove()
            }
            // e.target.parentNode.remove()
          }
          else if(e.target.lastChild.tagName === 'BUTTON'){
            // console.log(e.target.lastChild.innerHTML.length);
            // e.preventDefault()
            if (e.target.lastChild.innerHTML.length === 1) {
              e.preventDefault()
              e.target.lastChild.innerHTML = ''
            }
          }
          else if(e.target.lastChild.tagName === 'VIDEO'){
            e.target.innerHTML = ''
          }
          else{
            // console.log(e.target.innerHTML);
            if (e.target.innerHTML === "<br>") {
              if (e.target.parentNode.previousElementSibling) {
                e.preventDefault()
                const el = e.target.parentNode.previousElementSibling.lastChild
                this.placeCaretAtEnd(el)
              }
              e.target.parentNode.remove()
            }
          }
        }

        if (keyCode === 40) {
          if(e.target.parentNode.nextElementSibling){
            const el = e.target.parentNode.nextElementSibling.lastChild
            this.placeCaretAtEnd(el)
          }
        }
        if (keyCode === 38) {
          if(e.target.parentNode.previousElementSibling){
            const el = e.target.parentNode.previousElementSibling.lastChild
            this.placeCaretAtEnd(el)
          }
        }
      }

      if (e.target.parentNode.classList.contains("list-content")) {
        const keyCode = e.keyCode
        if (keyCode === 13 && !e.shiftKey) { // enter keyCode
          // Don't generate a new line
          e.preventDefault()
          if (e.target.innerHTML.length === 0) {
            e.target.innerHTML = " "
          }
          this.insertBullet(keyCode)
        }

        if (keyCode === 8) { // delete/backspace keyCode
          if (e.target.innerHTML.length === 0) {
            // console.log(e.target.parentNode.className);
            if (e.target.parentNode.parentNode.parentNode.className === "yz-page-block") {
              if (e.target.parentNode.parentNode.parentNode.previousElementSibling) {
                e.preventDefault()
                // console.log(e.target.parentNode.parentNode.parentNode.previousElementSibling.lastChild);
                if (e.target.parentNode.parentNode.parentNode.previousElementSibling.lastChild.classList.contains("yz-text-element")) {
                  const el = e.target.parentNode.parentNode.parentNode.previousElementSibling.lastChild
                  this.placeCaretAtEnd(el)
                }
                if (e.target.parentNode.parentNode.parentNode.previousElementSibling.lastChild.classList.contains("yz-list-element")) {
                  const el = e.target.parentNode.parentNode.parentNode.previousElementSibling.lastChild.lastChild.lastChild
                  this.placeCaretAtEnd(el)
                }
              }
              e.target.parentNode.parentNode.parentNode.remove()
            }
            // e.target.parentNode.remove()
          }else if (e.target.innerHTML === "<br>") {
              if (e.target.parentNode.parentNode.parentNode.previousElementSibling) {
                e.preventDefault()
                const el = e.target.parentNode.parentNode.parentNode.previousElementSibling.lastChild
                this.placeCaretAtEnd(el)
              }
              e.target.parentNode.parentNode.parentNode.remove()
          }
        }
      }
    })

    editor.addEventListener( 'click', (e) => {
      const clickedEl = e.target
      // console.log(clickedEl.tagName);
      if (clickedEl.tagName === 'IMG' || clickedEl.tagName === 'BUTTON' || clickedEl.tagName === 'VIDEO') {
        // clickedEl.parentNode.focus()
        // console.log(clickedEl.parentNode);
        this.placeCaretAtEnd(clickedEl.parentNode)
      }
    })

    editor.addEventListener('paste', function (e) { //code to paste as plain text
        // Prevent the default action
        e.preventDefault();

        // Get the copied text from the clipboard
        const text = e.clipboardData
            ? (e.originalEvent || e).clipboardData.getData('text/plain')
            : // For IE
            window.clipboardData
            ? window.clipboardData.getData('Text')
            : '';

        if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);
        } else {
            // Insert text at the current position of caret
            const range = document.getSelection().getRangeAt(0);
            range.deleteContents();

            const textNode = document.createTextNode(text);
            range.insertNode(textNode);
            range.selectNodeContents(textNode);
            range.collapse(false);

            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    });
  },
  methods: {
    getSelectedText() {
      return window.getSelection().toString()
    },
    getFocusedElement() {
      const node = window.getSelection().anchorNode;
      if (node) {
        return (node.nodeType !== 1 ? node.parentNode : node);
      }else{
        return false
      }
    },
    getSelectedHtml() {
      var html = "";
      if (typeof window.getSelection != "undefined") {
          var sel = window.getSelection();
          if (sel.rangeCount) {
              var container = document.createElement("div");
              for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                  container.appendChild(sel.getRangeAt(i).cloneContents());
              }
              html = container.innerHTML;
          }
      } else if (typeof document.selection != "undefined") {
          if (document.selection.type == "Text") {
              html = document.selection.createRange().htmlText;
          }
      }
      return html;
    },
    bold() {
      if (document.execCommand("bold")) {
        document.execCommand("normal");
      } else {
        document.execCommand("bold");
      }
    },
    italic() {
      if (document.execCommand("italic")) {
        document.execCommand("normal");
      } else {
        document.execCommand("italic");
      }
    },
    underline() {
      if (document.execCommand("underline")) {
        document.execCommand("normal");
      } else {
        document.execCommand("underline");
      }
    },
    strikeThrough() {
      if (document.execCommand("strikethrough")) {
        document.execCommand("normal");
      } else {
        document.execCommand("strikethrough");
      }
    },
    uploadImage() {
      
      // const URL = 'https://api-dummy.com/upload'; 
      const URL = 'https://api-test.yezza.io/documents'; 
      const elChoose = document.getElementById('chooseImage')
      const elUrl = document.getElementById('imageUrl')
      // console.log(elUrl.value);
      if (elChoose.files.length) {
        let data = new FormData();
        data.append('name', elChoose.files[0].name);
        data.append('file', elChoose.files[0]); 

        const size = elChoose.files[0].size/(1000*1000)

        // console.log(elChoose.files[0].size/(1000*1000));

        let config = {
          header : {
            'Content-Type' : 'image/*'
          }
        }
        if (size < 1) {
          axios.post(
            URL, 
            data,
            config
          ).then(
            response => {
              let newFile = {}
              newFile['filename'] = response.data.filename
              newFile['link'] = response.data.file
              // console.log(response.data.file)
              // console.log(response.data.filename)
              this.uploadedImage.push(newFile)
              window.localStorage.setItem('sales-page-images',JSON.stringify(this.uploadedImage))
              // const newBlockEl = document.createElement('div')
              const newTextEl = document.createElement('div')
              const newImageEl = document.createElement('img')
              
              // newBlockEl.setAttribute("class", "yz-page-block")
              newTextEl.setAttribute("class", "yz-text-element")
              newTextEl.setAttribute("contenteditable", "true")
              newTextEl.style.textAlign = 'center'
              newImageEl.setAttribute("src", response.data.file)
              newImageEl.setAttribute("width", "100%")
              newTextEl.setAttribute("placeholder", "click delete again to remove")

              newTextEl.appendChild(newImageEl)
              newTextEl.append("Image Caption")
              // newBlockEl.appendChild(newTextEl)

              const tempNode = document.createElement('div')
              tempNode.appendChild(newTextEl)
              if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
                this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
              }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
                // console.log(tempNode);
                this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
              }else{
                const editor = document.getElementById('heditor-wrapper')
                editor.appendChild(newTextEl)
              }
              this.openChooseImage = false
            }
          )
          // .catch(error => {
          //   alert(error.response.data.error)
          // })
        }else{
          alert("Note: Image size must be less than 1Mb")
        }
        
      }

      if (elUrl.value) {
        this.uploadedImage.push({
          filename: elUrl.value,
          link: elUrl.value
        })
        window.localStorage.setItem('sales-page-images',JSON.stringify(this.uploadedImage))
        // const newBlockEl = document.createElement('div')
        const newTextEl = document.createElement('div')
        const newImageEl = document.createElement('img')
        
        // newBlockEl.setAttribute("class", "yz-page-block")
        newTextEl.setAttribute("class", "yz-text-element")
        newTextEl.setAttribute("contenteditable", "true")
        newTextEl.style.textAlign = 'center'
        newImageEl.setAttribute("src", elUrl.value)
        newImageEl.setAttribute("width", "100%")
        newTextEl.setAttribute("placeholder", "click delete again to remove")

        newTextEl.appendChild(newImageEl)
        newTextEl.append("Image Caption")
        // newBlockEl.appendChild(newTextEl)

        const tempNode = document.createElement('div')
        tempNode.appendChild(newTextEl)
        if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
          this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
        }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
          // console.log(tempNode);
          this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
        }else{
          const editor = document.getElementById('heditor-wrapper')
          editor.appendChild(newTextEl)
        }
        this.openChooseImage = false
        elUrl.value = ''
      }
    },
    insertUploadedImage(link) {
      // const newBlockEl = document.createElement('div')
      const newTextEl = document.createElement('div')
      const newImageEl = document.createElement('img')
      
      // newBlockEl.setAttribute("class", "yz-page-block")
      newTextEl.setAttribute("class", "yz-text-element")
      newTextEl.setAttribute("contenteditable", "true")
      newTextEl.style.textAlign = 'center'
      newImageEl.setAttribute("src", link)
      newImageEl.setAttribute("width", "100%")
      newTextEl.setAttribute("placeholder", "click delete again to remove")

      newTextEl.appendChild(newImageEl)
      newTextEl.append("Image Caption")
      // newBlockEl.appendChild(newTextEl)

      const tempNode = document.createElement('div')
      tempNode.appendChild(newTextEl)
      if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
        this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
      }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
        // console.log(tempNode);
        this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
      }else{
        const editor = document.getElementById('heditor-wrapper')
        editor.appendChild(newTextEl)
      }
      this.openChooseImage = false
    },

    uploadVideo() {
      
      // const URL = 'https://api-dummy.com/upload'; 
      const URL = 'https://api-test.yezza.io/documents'; 
      const elChoose = document.getElementById('chooseVideo')
      const elUrl = document.getElementById('videoUrl')
      // console.log(elUrl.value);
      if (elChoose.files.length) {
        let data = new FormData();
        data.append('name', elChoose.files[0].name);
        data.append('file', elChoose.files[0]); 

        const size = elChoose.files[0].size/(10000*1000)

        // console.log(elChoose.files[0].size/(1000*1000));

        let config = {
          header : {
            'Content-Type' : 'video/*'
          }
        }
        if (size < 1) {
          axios.post(
            URL, 
            data,
            config
          ).then(
            response => {
              let newFile = {}
              newFile['filename'] = response.data.filename
              newFile['link'] = response.data.file
              // console.log(response.data.file)
              // console.log(response.data.filename)
              this.uploadedVideo.push(newFile)
              window.localStorage.setItem('sales-page-videos',JSON.stringify(this.uploadedVideo))
              // const newBlockEl = document.createElement('div')
              const newTextEl = document.createElement('div')
              const newVideoEl = document.createElement('video')
              const source = document.createElement('source')
              
              // newBlockEl.setAttribute("class", "yz-page-block")
              newTextEl.setAttribute("class", "yz-text-element")
              newTextEl.setAttribute("contenteditable", "true")
              newTextEl.style.textAlign = 'center'
              source.setAttribute("src", response.data.file)
              source.setAttribute("type", "video/*")
              newVideoEl.setAttribute("width", "100%")
              newVideoEl.setAttribute("controls", true)
              newTextEl.setAttribute("placeholder", "click delete again to remove")

              newVideoEl.appendChild(source)
              newTextEl.appendChild(newVideoEl)
              newTextEl.append("Video Caption")
              // newBlockEl.appendChild(newTextEl)

              const tempNode = document.createElement('div')
              tempNode.appendChild(newTextEl)
              if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
                this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
              }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
                // console.log(tempNode);
                this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
              }else{
                const editor = document.getElementById('heditor-wrapper')
                editor.appendChild(newTextEl)
              }
              this.openChooseVideo = false
            }
          )
          // .catch(error => {
          //   alert(error.response.data.error)
          // })
        }else{
          alert("Note: Video size must be less than 10Mb")
        }
        
      }

      if (elUrl.value) {
        this.uploadedVideo.push({
          filename: elUrl.value,
          link: elUrl.value
        })
        window.localStorage.setItem('sales-page-videos',JSON.stringify(this.uploadedVideo))
        // const newBlockEl = document.createElement('div')
        const newTextEl = document.createElement('div')
        const newVideoEl = document.createElement('video')
        const source = document.createElement('source')
        
        // newBlockEl.setAttribute("class", "yz-page-block")
        newTextEl.setAttribute("class", "yz-text-element")
        newTextEl.setAttribute("contenteditable", "true")
        newTextEl.style.textAlign = 'center'
        source.setAttribute("src", elUrl.value)
        // source.setAttribute("type", "video/*")
        newVideoEl.setAttribute("width", "100%")
        newVideoEl.setAttribute("controls", true)
        newTextEl.setAttribute("placeholder", "click delete again to remove")

        newVideoEl.appendChild(source)
        newTextEl.appendChild(newVideoEl)
        newTextEl.append("Video Caption")
        // newBlockEl.appendChild(newTextEl)

        const tempNode = document.createElement('div')
        tempNode.appendChild(newTextEl)
        if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
          this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
        }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
          // console.log(tempNode);
          this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
        }else{
          const editor = document.getElementById('heditor-wrapper')
          editor.appendChild(newTextEl)
        }
        this.openChooseImage = false
        elUrl.value = ''
      }
    },
    insertUploadedVideo(link) {
      // const newBlockEl = document.createElement('div')
      const newTextEl = document.createElement('div')
      const newVideoEl = document.createElement('video')
      const source = document.createElement('source')
      
      // newBlockEl.setAttribute("class", "yz-page-block")
      newTextEl.setAttribute("class", "yz-text-element")
      newTextEl.setAttribute("contenteditable", "true")
      newTextEl.style.textAlign = 'center'
      source.setAttribute("src", link)
      // source.setAttribute("type", "video/*")
      newVideoEl.setAttribute("width", "100%")
      newVideoEl.setAttribute("controls", true)
      newTextEl.setAttribute("placeholder", "click delete again to remove")

      newVideoEl.appendChild(source)
      newTextEl.appendChild(newVideoEl)
      newTextEl.append("Video Caption")
      // newBlockEl.appendChild(newTextEl)

      const tempNode = document.createElement('div')
      tempNode.appendChild(newTextEl)
      if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
        this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
      }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
        // console.log(tempNode);
        this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
      }else{
        const editor = document.getElementById('heditor-wrapper')
        editor.appendChild(newTextEl)
      }
      this.openChooseVideo = false
    },

    insertButton() {
      // const newBlockEl = document.createElement('div')
      const newTextEl = document.createElement('div')
      const button = document.createElement('button')
      // const span = document.createElement('span')
      
      // newBlockEl.setAttribute("class", "yz-page-block")
      newTextEl.setAttribute("class", "yz-text-element")
      newTextEl.setAttribute("contenteditable", "true")
      button.setAttribute("class", "yz-btn-default")
      button.style.backgroundColor = this.currentButtonColor
      button.style.borderRadius = this.currentButtonRadius

      button.append('Button Text')
      // button.appendChild(span)
      newTextEl.setAttribute("placeholder", "click delete again to remove")

      newTextEl.appendChild(button)
      // newBlockEl.appendChild(newTextEl)

      const tempNode = document.createElement('div')
      tempNode.appendChild(newTextEl)
      if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
        this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
      }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
        // console.log(tempNode);
        this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
      }else{
        const editor = document.getElementById('heditor-wrapper')
        editor.appendChild(newTextEl)
      }
      this.openChooseImage = false
    },
    
    insertTextElement(ele,keycode) {
      // const newBlockEl = document.createElement('div')
      const newTextEl = document.createElement('p')
      if (keycode !== 13) {
        if (this.getFocusedElement() && this.getFocusedElement().classList.contains('yz-text-element') && this.getSelectedHtml() === this.getFocusedElement().innerHTML){
          this.removeClassByPrefix(this.getFocusedElement(),'textsize-')
          this.getFocusedElement().classList.add(ele)
          this.getFocusedElement().focus()
        }else if(this.getFocusedElement().tagName === 'SPAN') {
          if (this.getFocusedElement().parentNode.classList.contains(ele)) {
            if(this.getFocusedElement().classList.length === 1)
              this.unwrapSelected(this.getFocusedElement())
            else
              this.removeClassByPrefix(this.getFocusedElement(),'textsize-')
          }else{
            this.removeClassByPrefix(this.getFocusedElement(),'textsize-')
            this.getFocusedElement().classList.add(ele)
          }
        }else if(this.getSelectedHtml()){
          if (!this.getFocusedElement().classList.contains(ele)){
            const span = '<span class="'+ ele +'">' + this.getSelectedText() + '</span>'
            this.getFocusedElement().innerHTML = this.getFocusedElement().innerHTML.replace(new RegExp(this.getSelectedText() , 'g'), span)
          }
        }else{
          // newBlockEl.setAttribute("class", "yz-page-block")

          newTextEl.setAttribute("class", "yz-text-element")
          newTextEl.setAttribute("contenteditable", "true")
          newTextEl.setAttribute("placeholder", "Your text here")
          newTextEl.classList.remove('textsize-headline','textsize-subheadline','textsize-title','textsize-paragraph')
          newTextEl.classList.add(ele)
          newTextEl.innerHTML = ""
          // el.setAttribute("draggable","true")
          // newBlockEl.appendChild(newTextEl)

          const tempNode = document.createElement('div')
          tempNode.appendChild(newTextEl)
          if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
            this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
            // console.log('new block created 1');
            // console.log(this.getFocusedElement().parentNode.nextElementSibling.lastChild);
            this.getFocusedElement().nextElementSibling.focus()
          }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
            // console.log(tempNode);
            this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
            // console.log('new block created 1');
            // console.log(this.getFocusedElement().parentNode.nextElementSibling.lastChild);
            this.getFocusedElement().parentNode.parentNode.nextElementSibling.lastChild.lastChild.focus()
          }else{
            const editor = document.getElementById('heditor-wrapper')
            editor.appendChild(newTextEl)
            // console.log('new block created 2');
            // console.log(editor.lastChild);
            editor.lastChild.focus()
          }
        }
          
      }else{
        // newBlockEl.setAttribute("class", "yz-page-block")

        newTextEl.setAttribute("class", "yz-text-element")
        newTextEl.setAttribute("contenteditable", "true")
        newTextEl.setAttribute("placeholder", "Your text here")
        newTextEl.classList.remove('textsize-headline','textsize-subheadline','textsize-title','textsize-paragraph')
        newTextEl.classList.add(ele)
        newTextEl.innerHTML = ""
        // el.setAttribute("draggable","true")
        // newBlockEl.appendChild(newTextEl)

        const tempNode = document.createElement('div')
        tempNode.appendChild(newTextEl)
        if (this.getFocusedElement() && this.getFocusedElement().classList.contains("yz-text-element")) {
          this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
          // console.log('new block created 1');
          // console.log(this.getFocusedElement().parentNode.nextElementSibling.lastChild);
          this.getFocusedElement().nextElementSibling.focus()
        }else if (this.getFocusedElement() && this.getFocusedElement().tagName === 'LI') {
          // console.log(tempNode);
          this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
          // console.log('new block created 1');
          // console.log(this.getFocusedElement().parentNode.nextElementSibling.lastChild);
          this.getFocusedElement().parentNode.parentNode.nextElementSibling.lastChild.focus()
        }else{
          const editor = document.getElementById('heditor-wrapper')
          editor.appendChild(newTextEl)
          // console.log('new block created 2');
          // console.log(editor.lastChild);
          editor.lastChild.focus()
        }
      }
    },
    unwrapSelected(el) {
      // const sel = this.getFocusedElement()
      const sel = el
      const parent = el.parentNode
      // console.log(sel,parent);
      while(sel.firstChild) parent.insertBefore(sel.firstChild, sel)
      parent.removeChild(sel)
      // console.log(parent);
    },
    insertToEditor() {
      const editor = document.getElementById('heditor-wrapper')
      // console.log(editor.innerHTML);
      if (!editor.innerHTML) {
        this.insertTextElement('textsize-headline')
      }
    },
    indentOutdent(type) {
      const focused = this.getFocusedElement()
      let textIndentVal = window.getComputedStyle(focused).getPropertyValue('text-indent')
      textIndentVal = parseInt(textIndentVal.replace("px",""))
      if (type === 'inc') {
        textIndentVal += 20
      }
      if (type === 'dec' && textIndentVal >= 20) {
        textIndentVal -= 20
      }
      if (type === 'dec' && (textIndentVal > 0 && textIndentVal < 20)) {
        textIndentVal = 0
      }
      focused.style.textIndent = textIndentVal+'px'
    },
    insertList(type,keycode){
      const focused = this.getFocusedElement()
      const ulist = document.createElement(type)
      const list = document.createElement('li')

      ulist.setAttribute("class","yz-list-element")
      if (type === 'ul') {
        list.setAttribute("data-content",this.currentBullet)
      }
      
      ulist.appendChild(list)

      if (focused.classList.contains("yz-text-element") && keycode !== 13 && focused.innerHTML.length < 2) {
        focused.appendChild(ulist)
        focused.lastChild.lastChild.focus()
      }
      if (focused.parentNode.classList.contains("yz-text-element") && keycode !== 13 && focused.innerHTML.length > 1) {
        // const newBlockEl = document.createElement('div')
        const newTextEl = document.createElement('div')

        // newBlockEl.setAttribute("class", "yz-page-block")

        newTextEl.setAttribute("class", "yz-text-element")
        newTextEl.setAttribute("contenteditable", "true")
        newTextEl.setAttribute("placeholder", "Your text here")
        newTextEl.classList.add("textsize-paragraph")

        newTextEl.appendChild(ulist)
        // el.setAttribute("draggable","true")
        // newBlockEl.appendChild(newTextEl)

        const tempNode = document.createElement('div')
        tempNode.appendChild(newTextEl)
        if (this.getFocusedElement().className === "yz-text-element") {
          this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
          // console.log('new block created 1');
          // console.log(this.getFocusedElement().parentNode.nextElementSibling.lastChild);
          this.getFocusedElement().nextElementSibling.lastChild.focus()
        }else{
          const editor = document.getElementById('heditor-wrapper')
          editor.appendChild(newTextEl)
          // console.log('new block created 2');
          // console.log(editor.lastChild);
          editor.lastChild.focus()
        }
      }
    },
    insertBullet(keycode) {
      const focused = this.getFocusedElement()
      // console.log(focused);
      if (focused.classList.contains("yz-text-element") && keycode !== 13 && focused.innerHTML.length < 2) {
        focused.removeAttribute("class",true)
        focused.removeAttribute("placeholder",true)
        focused.removeAttribute("contenteditable",true)
        focused.setAttribute("class","yz-list-element")

        const listBullet = document.createElement('div')
        const bullet = document.createElement('div')
        const listContent = document.createElement('div')
        const content = document.createElement('div')

        listBullet.setAttribute("class","list-bullet")
        listContent.setAttribute("class","list-content")
        bullet.setAttribute("class","bullet")
        bullet.setAttribute("data-content",this.currentBullet)

        content.setAttribute("class","textsize-paragraph")
        content.setAttribute("contenteditable", "true")
        content.setAttribute("placeholder", "Your text here")
        
        listContent.appendChild(content)
        listBullet.appendChild(bullet)

        focused.appendChild(listBullet)
        focused.appendChild(listContent)
        return
      }
      if(focused.classList.contains("yz-text-element") && keycode !== 13 && focused.innerHTML.length > 2){
        // const newBlockEl = document.createElement('div')
        const newListEL = document.createElement('div')
        const listBullet = document.createElement('div')
        const bullet = document.createElement('div')
        const listContent = document.createElement('div')
        const content = document.createElement('div')

        // newBlockEl.setAttribute("class", "yz-page-block")
        newListEL.setAttribute("class","yz-list-element")
        
        listBullet.setAttribute("class","list-bullet")
        listContent.setAttribute("class","list-content")

        bullet.setAttribute("class","bullet")
        bullet.setAttribute("data-content",this.currentBullet)

        content.setAttribute("class","textsize-paragraph")
        content.setAttribute("contenteditable", "true")
        content.setAttribute("placeholder", "Your text here")
        
        listContent.appendChild(content)
        listBullet.appendChild(bullet)

        newListEL.appendChild(listBullet)
        newListEL.appendChild(listContent)
        
        
        // newBlockEl.appendChild(newListEL)

        const tempNode = document.createElement('div')
        tempNode.appendChild(newListEL)

        if (this.getFocusedElement().className === "yz-text-element") {
          this.getFocusedElement().insertAdjacentHTML("afterend",tempNode.innerHTML)
          // console.log('new block created 1');
          // console.log(this.getFocusedElement().parentNode.nextElementSibling.lastChild);
          this.getFocusedElement().nextElementSibling.lastChild.focus()
        }else{
          const editor = document.getElementById('heditor-wrapper')
          editor.appendChild(newListEL)
          // console.log('new block created 2');
          // console.log(editor.lastChild);
          editor.lastChild.focus()
        }
      }
      // console.log(focused.innerHTML.length);
      if (focused.parentNode.classList.contains("list-content") && focused.innerHTML.length > 1) {
        // const newBlockEl = document.createElement('div')
        const newListEL = document.createElement('div')
        const listBullet = document.createElement('div')
        const bullet = document.createElement('div')
        const listContent = document.createElement('div')
        const content = document.createElement('div')

        // newBlockEl.setAttribute("class", "yz-page-block")
        newListEL.setAttribute("class","yz-list-element")
        
        listBullet.setAttribute("class","list-bullet")
        listContent.setAttribute("class","list-content")

        bullet.setAttribute("class","bullet")
        bullet.setAttribute("data-content",this.currentBullet)

        content.setAttribute("class","textsize-paragraph")
        content.setAttribute("contenteditable", "true")
        content.setAttribute("placeholder", "Your text here")
        
        listContent.appendChild(content)
        listBullet.appendChild(bullet)

        newListEL.appendChild(listBullet)
        newListEL.appendChild(listContent)
        
        
        // newBlockEl.appendChild(newListEL)

        const tempNode = document.createElement('div')
        tempNode.appendChild(newListEL)
        if (this.getFocusedElement().parentNode.classList.contains("list-content")) {
          this.getFocusedElement().parentNode.parentNode.insertAdjacentHTML("afterend",tempNode.innerHTML)
          // console.log(this.getFocusedElement().parentNode.parentNode.parentNode.nextElementSibling.lastChild.lastChild.lastChild);
          this.getFocusedElement().parentNode.parentNode.nextElementSibling.lastChild.lastChild.focus()
        }else{
          const editor = document.getElementById('heditor-wrapper')
          editor.appendChild(newListEL)
          editor.lastChild.lastChild.focus()
        }

        return
      }else{
        // console.log(focused.parentNode);
        if (focused.parentNode.classList.contains("list-content")) {
          const listEl = focused.parentNode.parentNode
          // console.log(listEl);
          listEl.innerHTML = ''
          listEl.removeAttribute("class",true)
          listEl.setAttribute("class", "yz-text-element")
          listEl.setAttribute("contenteditable", "true")
          listEl.setAttribute("placeholder", "Your text here")
          listEl.classList.add('textsize-paragraph')
          listEl.focus()
          return
        }
      }
    },
    removeClassByPrefix(node, prefix) {
      var regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
      node.className = node.className.replace(regx, '');
      return node;
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection != "undefined"
        && typeof document.createRange != "undefined") {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection()
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    alignText(pos) {
      const el = this.getFocusedElement()
      if (el.classList.contains('yz-text-element')) {
        el.style.textAlign = pos
      }else if(el.tagName === 'SPAN'){
        el.parentNode.style.textAlign = "-webkit-"+pos
      }else if(el.tagName === 'LI'){
        el.parentNode.parentNode.style.textAlign = "-webkit-"+pos
      }
    },
    changeTextColor(color) {
      const selected = this.getSelectedHtml()
      const focused = this.getFocusedElement()
      if (selected.length < 1) {
        alert('Select at least 1 character')
        return
      }
      if (focused.classList.contains('yz-text-element') || focused.tagName === 'LI') {
        const span = '<span class="'+ color +'">' + selected + '</span>'
        if (selected === focused.innerHTML) {
          focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
          const innerChild = focused.children[0].children
          if (innerChild.length > 0) {
            for (let index = 0; index < innerChild.length; index++) {
              const element = innerChild[index];
              // console.log(element.style);
              this.removeClassByPrefix(element,'textcolor-')
              if (element.classList.length < 1 && element.style.length < 1)
                this.unwrapSelected(element)
              index --
            }
          }
        }else{
          focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
        }
        return
      }
      if (focused.tagName === 'SPAN') {
        if (selected === focused.innerHTML) {
          this.removeClassByPrefix(focused,'textcolor-')
          focused.classList.add(color)

          const innerChild = focused.children
          if (innerChild.length > 0) {
            for (let index = 0; index < innerChild.length; index++) {
              const element = innerChild[index];
              if (element.classList.length === 1)
                this.unwrapSelected(element)
              else
                this.removeClassByPrefix(element,'textcolor-')
              index --
            }
          }
          return
        }
        if (selected.length > focused.innerHTML.length) {
          const span = '<span class="'+ color +'">' + selected + '</span>'
          focused.parentNode.innerHTML = focused.parentNode.innerHTML.replace(new RegExp(selected , 'g'), span)
          if (this.getFocusedElement().children.length > 0) {
            for (let index = 0; index < this.getFocusedElement().children.length; index++) {
              const element = this.getFocusedElement().children[index];
              if (element.classList.contains(color)) {
                const innerChild = element.children
                if (innerChild.length > 0) {
                  for (let index = 0; index < innerChild.length; index++) {
                    const element = innerChild[index];
                    if (element.classList.length === 1)
                      this.unwrapSelected(element)
                    else
                      this.removeClassByPrefix(element,'textcolor-')
                    index --
                  }
                }
              }
            }
          }
          return
        }
        const parentTextColor = window.getComputedStyle(focused.parentNode, null).getPropertyValue('color');
        if ((parentTextColor === 'rgb(0, 0, 0)' && color === 'textcolor-default') || focused.parentNode.classList.contains(color)) {
          this.removeClassByPrefix(focused,'textcolor-')
          if (focused.classList.length < 1)
            this.unwrapSelected(focused)
            
          return
        }
        const span = '<span class="'+ color +'">' + selected + '</span>'
        focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
      }
    },
    changeBgColor(color) {
      const selected = this.getSelectedHtml()
      const focused = this.getFocusedElement()
      if (selected.length < 1) {
        alert('Select at least 1 character')
        return
      }
      if (focused.classList.contains('yz-text-element') || focused.tagName === 'LI') {
        const span = '<span class="'+ color +'">' + selected + '</span>'
        if (selected === focused.innerHTML) {
          focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
          const innerChild = focused.children[0].children
          if (innerChild.length > 0) {
            for (let index = 0; index < innerChild.length; index++) {
              const element = innerChild[index];
              // console.log(element.style);
              this.removeClassByPrefix(element,'bgcolor-')
              if (element.classList.length < 1 && element.style.length < 1)
                this.unwrapSelected(element)
              index --
            }
          }
        }else{
          focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
        }
        return
      }
      if (focused.tagName === 'SPAN') {
        if (selected === focused.innerHTML) {
          this.removeClassByPrefix(focused,'bgcolor-')
          focused.classList.add(color)

          const innerChild = focused.children
          if (innerChild.length > 0) {
            for (let index = 0; index < innerChild.length; index++) {
              const element = innerChild[index];
              if (element.classList.length === 1)
                this.unwrapSelected(element)
              else
                this.removeClassByPrefix(element,'bgcolor-')
              index --
            }
          }
          return
        }
        if (selected.length > focused.innerHTML.length) {
          const span = '<span class="'+ color +'">' + selected + '</span>'
          focused.parentNode.innerHTML = focused.parentNode.innerHTML.replace(new RegExp(selected , 'g'), span)
          if (this.getFocusedElement().children.length > 0) {
            for (let index = 0; index < this.getFocusedElement().children.length; index++) {
              const element = this.getFocusedElement().children[index];
              if (element.classList.contains(color)) {
                const innerChild = element.children
                if (innerChild.length > 0) {
                  for (let index = 0; index < innerChild.length; index++) {
                    const element = innerChild[index];
                    if (element.classList.length === 1)
                      this.unwrapSelected(element)
                    else
                      this.removeClassByPrefix(element,'bgcolor-')
                    index --
                  }
                }
              }
            }
          }
          return
        }
        const parentBgColor = window.getComputedStyle(focused.parentNode, null).getPropertyValue('background-color');
        if ((parentBgColor === '' && color === 'bgcolor-default') || focused.parentNode.classList.contains(color)) {
          this.removeClassByPrefix(focused,'bgcolor-')
          if (focused.classList.length < 1)
            this.unwrapSelected(focused)
            
          return
        }
        const span = '<span class="'+ color +'">' + selected + '</span>'
        focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
      }
    },
    changeFontFamily(font) {
      const selected = this.getSelectedHtml()
      const focused = this.getFocusedElement()
      if (selected.length < 1) {
        alert('Select at least 1 character')
        return
      }
      if (focused.classList.contains('yz-text-element') || focused.tagName === 'LI') {
        const span = '<span class="'+ font +'">' + selected + '</span>'
        if (selected === focused.innerHTML) {
          focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
          const innerChild = focused.children[0].children
          if (innerChild.length > 0) {
            for (let index = 0; index < innerChild.length; index++) {
              const element = innerChild[index];
              if (element.classList.length === 1)
                this.unwrapSelected(element)
              else
                this.removeClassByPrefix(element,'textfont-')
              index --
            }
          }
        }else{
          focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
        }
        return
      }
      if (focused.tagName === 'SPAN') {
        if (selected === focused.innerHTML) {
          this.removeClassByPrefix(focused,'textfont-')
          focused.classList.add(font)

          const innerChild = focused.children
          if (innerChild.length > 0) {
            for (let index = 0; index < innerChild.length; index++) {
              const element = innerChild[index];
              if (element.classList.length === 1)
                this.unwrapSelected(element)
              else
                this.removeClassByPrefix(element,'textfont-')
              index --
            }
          }
          return
        }
        if (selected.length > focused.innerHTML.length) {
          const span = '<span class="'+ font +'">' + selected + '</span>'
          focused.parentNode.innerHTML = focused.parentNode.innerHTML.replace(new RegExp(selected , 'g'), span)
          if (this.getFocusedElement().children.length > 0) {
            for (let index = 0; index < this.getFocusedElement().children.length; index++) {
              const element = this.getFocusedElement().children[index];
              if (element.classList.contains(font)) {
                const innerChild = element.children
                if (innerChild.length > 0) {
                  for (let index = 0; index < innerChild.length; index++) {
                    const element = innerChild[index];
                    if (element.classList.length === 1)
                      this.unwrapSelected(element)
                    else
                      this.removeClassByPrefix(element,'textfont-')
                    index --
                  }
                }
              }
            }
          }
          return
        }
        const parentTextColor = window.getComputedStyle(focused.parentNode, null).getPropertyValue('font-family');
        if ((parentTextColor === "'Inter', sans-serif" && font === 'textcolor-inter') || focused.parentNode.classList.contains(font)) {
          if (focused.classList.length === 1)
            this.unwrapSelected(focused)
          else
            this.removeClassByPrefix(focused,'textfont-')
            
          return
        }
        const span = '<span class="'+ font +'">' + selected + '</span>'
        focused.innerHTML = focused.innerHTML.replace(new RegExp(selected , 'g'), span)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    pre {
      white-space: pre-wrap;       /* Since CSS 2.1 */
      white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
      white-space: -pre-wrap;      /* Opera 4-6 */
      white-space: -o-pre-wrap;    /* Opera 7 */
      word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }
    .MyBuilder{
      max-width: 1240px;
      margin: auto;
      // height: 780px;
    }
    #myeditorToolbar{
      padding: 20px;
      border: 1px solid black;
      border-bottom: none;
      border-radius: 10px 10px 0 0;
      background-color: rgba(0,0,0,.4);
    }
    #myeditor{
      padding: 20px;
      border: 1px solid black;
      border-radius: 0 0 10px 10px;
    }
    .mainbar-btn{
      border: none !important;
      border-radius: 5px;
      background-color: transparent !important;
      box-shadow: none;
      height: fit-content !important;
      width: fit-content !important;
      padding: 8px !important;
      min-width: 45px !important;
    }
    .mainbar-btn:focus{
      border-radius: 10px 10px 0 0;
    }
    .mainbar-btn::before{
      transition: none;
    }
    .mainbar-btn:focus::before{
      opacity: 1 !important;
      background-color: #fff !important;
    }
    .mainbar-btn:hover::before{
      opacity: 0;
      background-color: transparent;
    }
    .type-setting-1{
      align-items: center;
      margin: 0;
      .mainbar-btn{
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
        text-transform: none;
      }
    }
    .type-setting-2{
      align-items: center;
      margin: 0;
      .mainbar-btn{
        letter-spacing: inherit;
        text-indent: 0;
        text-transform: capitalize;
      }
    }
    .type-setting-3{
      align-items: center;
      margin: 0;
      .mainbar-btn{
        font-size: 17px;
        line-height: 27px;
        letter-spacing: -0.015em;
      }
    }

    .fontcolor-prev{
      background-color: black;
      height: 18px;
      width: 18px;
      border-radius: 18px;
      align-self: center;
      margin-right: 11px;
    }
    .fontbg-prev{
      width: 11.8px;
      height: 14px;
      border-radius: 2px;
      margin-right: 11px;
      font-size: 9.5px;
      line-height: 14.2px;
      font-weight: 600;
    }

    ::v-deep{
      .mainbar-btn .v-btn__content{
        justify-content: flex-start !important;
      }
      [contenteditable]:empty:after, .forcePlaceholder:after {
        content: attr(placeholder);
        color: rgb(212, 212, 212);
        // display: none;
      }
      [contenteditable]:focus:empty:after, .forcePlaceholder:focus:after {
        content: attr(placeholder);
        color: rgb(212, 212, 212);
        // display: unset;
      }
      [contenteditable] {
          -webkit-tap-highlight-color: transparent;
      }
      [contenteditable]:focus-visible{
        outline: none;
      }
      .v-ripple__container{
        display: none;
      }
    }
</style>
<style>
.v-menu__content {
  box-shadow: 0px 6px 10px -5px rgba(0,0,0,0.3) !important;
  border-radius: 0 10px 10px 10px !important;
  max-width: 600px !important;
  min-width: fit-content !important;
}
.v-menu__content .v-card{
  /* border: 1px solid rgba(0,0,0,.3); */
  border-radius: 0 !important;
  box-shadow: none !important;
}
</style>
